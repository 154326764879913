<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-icon color="primary" class="mr-4">mdi-tablet-cellphone</v-icon>
            <span class="subtitle-1">{{ $t('innerSideNav.mieleWorksheet') }}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pa-6">
            <v-row justify="end" class="ma-0">
              <v-col cols="12" sm="6" class="pa-0">
                <v-row class="ma-0">
                  <v-btn color="primary"
                         class="mr-3"
                         @click="addNewAppleWorksheet">
                    {{ $t('button.createNew') }}
                  </v-btn>
                  <v-btn color="primary"
                         :disabled="selectedWorksheets.length < 1"
                         @click="getSelectedWorksheetsIds">
                    {{ $t('button.export') }}
                  </v-btn>
                </v-row>
              </v-col>
              <v-col cols="12" sm="6" class="pa-0">
                <v-text-field
                  v-model="search"
                  :aria-label="$t('search')"
                  :label="$t('search')"
                  filled
                  prepend-inner-icon="mdi-magnify"
                  persistent-hint
                  clearable
                  :hint="$t('inputMessages.searchHint')"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-data-table
              v-model="selectedWorksheets"
              show-select
              :locale="$i18n.locale"
              :headers="tableHeader"
              :items="worksheets"
              :search="search"
              :sort-by="['createdAt']"
              :sort-desc="['true']"
              :loading="loadingData"
              :loading-text="$t('loadingText')"
              :no-data-text="$t('noDataText.general')"
              :no-results-text="`${$t('noDataText.noSearchResult1')} ${search} ${$t('noDataText.noSearchResult2')}`"
            >
              <template v-slot:item.createdAt="{item}">
                {{ item.createdAt.substring(0, 10) }}
              </template>
              <template v-slot:item.btn="{item}">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn icon color="primary" class="mr-3" @click="seeWorksheetDetails(item.id)" v-on="on">
                      <v-icon>
                        mdi-eye
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{ $t('button.view') }}
                  </span>
                </v-tooltip>
                <!--v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn icon color="primary" class="mr-3" @click="seeWorksheetDetails(item.id)" v-on="on">
                      <v-icon>
                        mdi-printer
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{ $t('button.print') }}
                  </span>
                </v-tooltip-->
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getURA } from 'src/utils/jwtHelper';

export default {
  name: 'MieleListPage',
  components: {
    //
  },
  props: {
    //
  },
  async beforeMount() {
    // if (this.$store.state.authModule.user.profile.customerType === 'EMPLOYEE') {
    if (this.$store.state.authModule.user.profile.customerType === 'EMPLOYEE' && await getURA().split(',').includes('MIELE')) {
      this.worksheets = await this.loadTableData();
      this.loadingData = false;
    } else {
      await this.$router.push({name: 'iranyitopult'});
    }
  },
  data() {
    return {
      search: null,
      selectedWorksheets: [],
      selectedWorksheetsToSend: [],
      tableHeader: [
        { text: this.$t('lg.wsid'), value: 'id' },
        { text: this.$t('lg.dateOfOrder'), value: 'createdAt' },
        { text: this.$t('lg.customerName'), value: 'customerNname' },
        { text: this.$t('inputLabels.email'), value: 'email' },
        { text: this.$t('phoneNumber'), value: 'phone' },
        { text: this.$t('deviceType'), value: 'productTypeName' },
        { text: this.$t('inputLabels.warrantyType2'), value: 'warrantyTypeName' },
        { text: this.$t('actualStatus'), value: 'actualStatus' },
        { text: '', value: 'btn' },
      ],
      worksheets: [],
      loadingData: true,
    };
  },
  methods: {
    async loadTableData() {
      const response = await this.$store.dispatch('GetMieleWorksheetList');
      if (response.status === 200) {
        return response.data;
      }
      return [];
    },
    addNewAppleWorksheet() {
      this.$router.push({ name: 'miele-munkalap-uj', params: { ws: 'uj-megrendeles' } });
    },
    seeWorksheetDetails(wsid) {
      this.$router.push({ name: 'miele-munkalap-reszletek', params: { ws: wsid } });
    },
    getSelectedWorksheetsIds() {
      this.selectedWorksheetsToSend = [];
      this.selectedWorksheets.forEach((item) => {
        this.selectedWorksheetsToSend.push(item.serviceDetails.id);
      })
    },
  },
};
</script>

<style scoped>

</style>
